import React from 'react';
import '../css/page404.css';

export const Page404 = () => {
	return (
		<section className="page_404">
			<div className="container">
				<div className="row">
					<div className="col-sm-12 ">
						<div className="col-sm-10 col-sm-offset-1  text-center">
							<div className="four_zero_four_bg">
								<h1 className="text-center ">404</h1>
							</div>

							<div className="contant_box_404">
								<h3 className="h2">Look's like you're lost</h3>

								<p>The Page You Are Looking For Is Not Available!</p>

								{/* <a href="" className="link_404">
									Go to Home
								</a> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
