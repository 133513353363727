import React from 'react';
import { Outlet } from 'react-router-dom';

export const Nav = () => {
	return (
		<React.Fragment>
			<header>
				<div className="container-fluid">
					<div className="row">
						<div className="col-5">
							<a href="index.html">
								<img src="./dedawhite.svg" alt="deda Logo" width={50} height={55} />
							</a>
						</div>
						<div className="col-7">{/* Socials space */}</div>
					</div>
					{/* /row */}
				</div>
				{/* /container */}
			</header>
			<Outlet />
		</React.Fragment>
	);
};
