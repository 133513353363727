import { api } from './config';

export const QuestionApi = {
	getAllQuestions: async function () {
		const response = await api.request({
			url: `/question`,
			method: 'GET',
		});

		return response.data;
	},

	getQuestionById: async function (id) {
		const response = await api.request({
			url: `/questionaire/id/?qid=${id}`,
			method: 'GET',
		});

		return response.data;
	},
};
