import React from 'react';
import '../../css/loader.css';

export const Loader = () => {
	return (
		<div className="spinner-square">
			<div className="square-1 square"></div>
			<div className="square-2 square"></div>
			<div className="square-3 square"></div>
		</div>
	);
};
