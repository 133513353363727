import './css/style.css';
import './css/bootstrap.css';
import Home from './Pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from './components/Fallback';

import { Nav, Page404, Success } from './components/index';

const App = () => {
	return (
		<BrowserRouter>
			<ErrorBoundary FallbackComponent={Fallback}>
				<Routes>
					<Route path="/:token" element={<Nav />}>
						<Route index element={<Home />} />
					</Route>
					<Route path="/success" element={<Success />} />
					<Route path="*" element={<Page404 />} />
				</Routes>
			</ErrorBoundary>
		</BrowserRouter>
	);
};

export default App;
