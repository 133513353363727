import { useEffect, useState } from 'react';
import { QuestionApi } from '../Apis/apis';

export const QuestionThirteen = ({ questions, setQ1, setLoader, overLay, loader }) => {
	const [QuestionThirteen, setQuestionThirteen] = useState();

	const getQuestionThirteen = async () => {
		QuestionApi.getQuestionById(13)
			.then(({ question }) => {
				setQuestionThirteen(question.question);
				overLay.current.classList.remove('modal-open');
				setLoader(false);
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		getQuestionThirteen();
		overLay.current.classList.add('modal-open');
		setLoader(true);
	}, []);

	return (
		<div className="step">
			<h3 className="main_question">
				<strong>13 of 17</strong> {QuestionThirteen ? QuestionThirteen : 'loading...'}
			</h3>
			<div className="review_block_smiles">
				<ul className="clearfix">
					<li>
						<div className="container_smile">
							<input
								type="radio"
								id="very_bad_1"
								name="question_13"
								className="required"
								value="Very bad"
								onChange={setQ1}
								checked={questions.question_13 === 'Very bad'}
								disabled={loader}
							/>
							<label className="radio smile_1" htmlFor="very_bad_1">
								<span>Very bad</span>
							</label>
						</div>
					</li>
					<li>
						<div className="container_smile">
							<input
								type="radio"
								id="bad_1"
								name="question_13"
								className="required"
								value="Bad"
								onChange={setQ1}
								checked={questions.question_13 === 'Bad'}
								disabled={loader}
							/>
							<label className="radio smile_2" htmlFor="bad_1">
								<span>Bad</span>
							</label>
						</div>
					</li>
					<li>
						<div className="container_smile">
							<input
								type="radio"
								id="average_1"
								name="question_13"
								className="required"
								value="Average"
								onChange={setQ1}
								checked={questions.question_13 === 'Average'}
								disabled={loader}
							/>
							<label className="radio smile_3" htmlFor="average_1">
								<span>Average</span>
							</label>
						</div>
					</li>
					<li>
						<div className="container_smile">
							<input
								type="radio"
								id="good_1"
								name="question_13"
								className="required"
								value="Good"
								onChange={setQ1}
								checked={questions.question_13 === 'Good'}
								disabled={loader}
							/>
							<label className="radio smile_4" htmlFor="good_1">
								<span>Good</span>
							</label>
						</div>
					</li>
					<li>
						<div className="container_smile">
							<input
								type="radio"
								id="very_good_1"
								name="question_13"
								className="required"
								value="Very Good"
								onChange={setQ1}
								checked={questions.question_13 === 'Very Good'}
								disabled={loader}
							/>
							<label className="radio smile_5" htmlFor="very_good_1">
								<span>Very Good</span>
							</label>
						</div>
					</li>
				</ul>
				<div className="row justify-content-between add_bottom_25">
					<div className="col-4">
						<em>Very Bad</em>
					</div>
					<div className="col-4 text-right">
						<em>Excellent</em>
					</div>
				</div>
			</div>
		</div>
	);
};
