import { useEffect, useState } from 'react';
import { QuestionApi } from '../Apis/apis';

export const QuestionOne = ({ questions, setQ1, overLay, loader, setLoader }) => {
	const [questionOne, setQuestionOne] = useState();

	const getQuestionsOne = async () => {
		QuestionApi.getQuestionById(1)
			.then(({ question }) => {
				setQuestionOne(question.question);
				setLoader(false);
				overLay.current.classList.remove('modal-open');
			})
			.catch(error => {
				console.log(error.message);
			});
	};

	useEffect(() => {
		overLay.current.classList.add('modal-open');
		setLoader(true);

		getQuestionsOne();
	}, []);

	return (
		<div className="step">
			<h3 className="main_question mb-4">
				<strong>1 of 17</strong> {questionOne ? questionOne : 'loading...'}
			</h3>
			<div className="review_block">
				<ul>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="Family Medicine"
								name="question_1"
								className="required"
								defaultValue="Family Medicine"
								onChange={setQ1}
								checked={questions.question_1 === 'Family Medicine'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="Family Medicine" />
							<label htmlFor="Family Medicine" className="wrapper">
								Family Medicine
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="Obstetrics and Gynecology "
								name="question_1"
								className="required"
								defaultValue="Obstetrics and Gynecology "
								onChange={setQ1}
								checked={questions.question_1 === 'Obstetrics and Gynecology '}
								disabled={loader}
							/>
							<label className="radio" htmlFor="Obstetrics and Gynecology " />
							<label htmlFor="Obstetrics and Gynecology " className="wrapper">
								Obstetrics and Gynecology
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="IVF"
								name="question_1"
								className="required"
								value="IVF"
								onChange={setQ1}
								checked={questions.question_1 === 'IVF'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="IVF" />
							<label htmlFor="IVF" className="wrapper">
								IVF
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="Paediatrics"
								name="question_1"
								className="required"
								value="Paediatrics"
								onChange={setQ1}
								checked={questions.question_1 === 'Paediatrics'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="Paediatrics" />
							<label htmlFor="Paediatrics" className="wrapper">
								Paediatrics
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="Cardiology"
								name="question_1"
								className="required"
								value="Cardiology"
								onChange={setQ1}
								checked={questions.question_1 === 'Cardiology'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="Cardiology" />
							<label htmlFor="Cardiology" className="wrapper">
								Cardiology
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="ENT"
								name="question_1"
								className="required"
								value="ENT"
								onChange={setQ1}
								checked={questions.question_1 === 'ENT'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="ENT" />
							<label htmlFor="ENT" className="wrapper">
								ENT
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="GOPD"
								name="question_1"
								className="required"
								value="GOPD"
								onChange={setQ1}
								checked={questions.question_1 === 'GOPD'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="GOPD" />
							<label htmlFor="GOPD" className="wrapper">
								GOPD
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="Ophthalmology"
								name="question_1"
								className="required"
								value="Ophthalmology"
								onChange={setQ1}
								checked={questions.question_1 === 'Ophthalmology'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="Ophthalmology" />
							<label htmlFor="Ophthalmology" className="wrapper">
								Ophthalmology
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="Radiology"
								name="question_1"
								className="required"
								value="Radiology"
								onChange={setQ1}
								checked={questions.question_1 === 'Radiology'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="Radiology" />
							<label htmlFor="Radiology" className="wrapper">
								Radiology
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="Other"
								name="question_1"
								className="required"
								value="Other"
								onChange={setQ1}
								checked={questions.question_1 === 'Other'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="Other" />
							<label htmlFor="Other" className="wrapper">
								Other
							</label>
						</div>
					</li>
				</ul>
			</div>
			{/* /review_block*/}
		</div>
	);
};
