import React, { useEffect, useRef, useState } from 'react';
import main_icon from '../img/main_icon_1.svg';
import {
	QuestionOne,
	FollowupOne,
	QuestionThree,
	QuestionFour,
	QuestionFive,
	QuestionSix,
	QuestionSeven,
	QuestionEight,
	QuestionNine,
	QuestionTen,
	QuestionEleven,
	QuestionTwelve,
	QuestionThirteen,
	QuestionFourteen,
	QuestionFifteen,
	Recommend,
	Comments,
	Success,
	PopUp,
} from '../components/index';
import { useParams, Navigate } from 'react-router-dom';
import { Loader } from '../components/common/Loader';
import jwt_decode from 'jwt-decode';

const { REACT_APP_API_URL } = process.env;

const Home = () => {
	const [questions, setQuestions] = useState({
		question_1: '',
		question_2: '',
		question_3: '',
		question_4: '',
		question_5: '',
		question_6: '',
		question_7: '',
		question_8: '',
		question_9: '',
		question_10: '',
		question_11: '',
		question_12: '',
		question_13: '',
		question_14: '',
		question_15: '',
		question_16: '',
		additional_message: '',
	});

	const [step, setStep] = useState(1);
	const [isSubmitted, setIsSubmitted] = useState('PENDING');
	const [isLoading, setIsLoading] = useState(true);
	const [username, setUsername] = useState(null);

	const setQ1 = e => {
		const { name, value } = e.target;

		setQuestions(prev => {
			return { ...prev, [name]: value };
		});
	};

	const setStepForward = () => {
		setStep(prev => prev + 1);
	};
	const setStepbackward = () => {
		setStep(prev => prev - 1);
	};

	let response = [
		{
			question: 1,
			clinic: questions.question_1,
			answer: '',
		},
		{
			question: 2,
			clinic: questions.question_1,
			answer: questions.question_2,
		},
		{
			question: 3,
			clinic: questions.question_1,
			answer: questions.question_3,
		},
		{
			question: 4,
			clinic: questions.question_1,
			answer: questions.question_4,
		},
		{
			question: 5,
			clinic: questions.question_1,
			answer: questions.question_5,
		},
		{
			question: 6,
			clinic: questions.question_1,
			answer: questions.question_6,
		},
		{
			question: 7,
			clinic: questions.question_1,
			answer: questions.question_7,
		},
		{
			question: 8,
			clinic: questions.question_1,
			answer: questions.question_8,
		},
		{
			question: 9,
			clinic: questions.question_1,
			answer: questions.question_9,
		},
		{
			question: 10,
			clinic: questions.question_1,
			answer: questions.question_10,
		},
		{
			question: 11,
			clinic: questions.question_1,
			answer: questions.question_11,
		},
		{
			question: 12,
			clinic: questions.question_1,
			answer: questions.question_12,
		},
		{
			question: 13,
			clinic: questions.question_1,
			answer: questions.question_13,
		},
		{
			question: 14,
			clinic: questions.question_1,
			answer: questions.question_14,
		},
		{
			question: 15,
			clinic: '',
			answer: questions.question_15,
		},
		{
			question: 16,
			clinic: questions.question_1,
			answer: questions.question_16,
		},
		{
			question: 17,
			clinic: questions.question_1,
			answer: questions.additional_message,
		},
	];

	const { token } = useParams();
	// Check for Token if Exist

	useEffect(() => {
		let decoded = jwt_decode(token);
		setUsername(decoded.username);

		return () => {
			setUsername(null);
		};
	});

	// Json Parser Function
	const parseJSON = response => response.json();

	/**APIS FUNCTION */
	const asyncPostCall = async (question, userToken, clinic) => {
		const responseData = await fetch(`${REACT_APP_API_URL}/responses`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				response: question,
				token: userToken,
				clinic: clinic,
			}),
		});

		return parseJSON(responseData);
	};

	//Submit Function
	const handleSubmit = async e => {
		e.preventDefault();
		try {
			let returnedData = await asyncPostCall(response, token, questions.question_1);

			if (returnedData.success) {
				setIsSubmitted('SUBMITTED');
			} else {
				console.log('Error: ', returnedData);
				setIsSubmitted('NOT SUBMITTED');
			}
		} catch (error) {
			console.log('Error: ', error);
			setIsSubmitted('NOT SUBMITTED');
		}
	};

	const overLay = useRef(null);

	// check recognize State
	const formSubmitted = isSubmitted === 'SUBMITTED';
	const notSubmitted = isSubmitted === 'NOT SUBMITTED';

	if (formSubmitted) {
		return <Navigate replace to="/success" />;
	}

	return (
		<div className="wrapper_centering">
			<div className="container_centering" ref={overLay}>
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-xl-6 col-lg-6 d-flex align-items-center">
							<div className="main_title_1">
								<h3>
									<img src={main_icon} alt="" width={80} height={80} /> Visit Survey
								</h3>
								<p>
									Hi {username ?? '----'}, Thank you for visiting Deda Hospital. Your feedback is important to us! Please let us
									know how your visit went so we can serve you better.
								</p>
								<p>
									<em>- The Deda-Care Team</em>
								</p>
							</div>
						</div>
						{/* /col */}
						<div className="col-xl-5 col-lg-5">
							<div id="wizard_container">
								<div id="top-wizard">
									<div id="progressbar" />
								</div>
								{notSubmitted && <PopUp />}

								{/* /top-wizard */}

								<form id="wrapped" autoComplete="off">
									<input id="website" name="website" type="text" defaultValue />
									{/* Leave for security protection, read docs for details */}
									<div id="middle-wizard">
										{step === 1 && (
											<QuestionOne
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 1*/}

										{step === 2 && (
											<FollowupOne
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 2*/}

										{step === 3 && (
											<QuestionThree
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 3*/}

										{step === 4 && (
											<QuestionFour
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 4*/}

										{step === 5 && (
											<QuestionFive
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 5*/}

										{step === 6 && (
											<QuestionSix
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 6*/}

										{step === 7 && (
											<QuestionSeven
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 7*/}

										{step === 8 && (
											<QuestionEight
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 8*/}
										{step === 9 && (
											<QuestionNine
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 9*/}
										{step === 10 && (
											<QuestionTen
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 10*/}
										{step === 11 && (
											<QuestionEleven
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 11*/}

										{step === 12 && (
											<QuestionTwelve
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 12*/}
										{step === 13 && (
											<QuestionThirteen
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 13*/}
										{step === 14 && (
											<QuestionFourteen
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 14*/}
										{step === 15 && (
											<QuestionFifteen
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 15*/}

										{step === 16 && (
											<Recommend
												questions={questions}
												setQ1={setQ1}
												overLay={overLay}
												loader={isLoading}
												setLoader={setIsLoading}
											/>
										)}
										{/* /step 16*/}
										{step === 17 && <Comments setQ1={setQ1} overLay={overLay} loader={isLoading} setLoader={setIsLoading} />}
										{/* /step 17*/}
									</div>

									{/* /middle-wizard */}
									<div id="bottom-wizard">
										<button
											type="button"
											name="backward"
											className="backward"
											onClick={setStepbackward}
											style={step === 1 ? { display: 'none' } : null}>
											Prev
										</button>
										<button
											type="button"
											name="forward"
											className="forward"
											style={step === 17 ? { display: 'none' } : null}
											onClick={setStepForward}
											// disabled={
											// 	(questions.question_1 === '' && step === 1) ||
											// 	(questions.question_2 === '' && step === 2) ||
											// 	(questions.question_3 === '' && step === 3) ||
											// 	(questions.question_4 === '' && step === 4) ||
											// 	(questions.question_5 === '' && step === 5) ||
											// 	(questions.question_6 === '' && step === 6) ||
											// 	(questions.question_7 === '' && step === 7) ||
											// 	(questions.question_8 === '' && step === 8) ||
											// 	(questions.question_9 === '' && step === 9) ||
											// 	(questions.question_10 === '' && step === 10) ||
											// 	(questions.question_11 === '' && step === 11) ||
											// 	(questions.question_12 === '' && step === 12) ||
											// 	(questions.question_13 === '' && step === 13) ||
											// 	(questions.question_14 === '' && step === 14) ||
											// 	(questions.question_15 === '' && step === 15) ||
											// 	(questions.question_16 === '' && step === 16)
											// }
										>
											Next
										</button>
										<button
											type="submit"
											name="process"
											className="submit"
											style={step !== 17 ? { display: 'none' } : null}
											onClick={handleSubmit}>
											Submit
										</button>
									</div>
									{isLoading && <Loader />}

									{/* /bottom-wizard */}
								</form>
							</div>
							{/* /Wizard container */}
						</div>
						{/* /col */}
					</div>
				</div>
				{/* /row */}
			</div>
			{/* /container_centering */}

			{/* /footer */}
		</div>
	);
};

export default React.memo(Home);
