import { useState, useEffect } from 'react';
import { QuestionApi } from '../Apis/apis';

export const Recommend = ({ questions, setQ1, overLay, setLoader, loader }) => {
	const [recommend, setRecommend] = useState();

	const getRecommend = async () => {
		QuestionApi.getQuestionById(16)
			.then(({ question }) => {
				setRecommend(question.question);
				overLay.current.classList.remove('modal-open');
				setLoader(false);
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		getRecommend();
		overLay.current.classList.add('modal-open');
		setLoader(true);
	}, []);

	return (
		<div className="step">
			<h3 className="main_question mb-4">
				<strong>16 of 17</strong> {recommend ? recommend : 'loading...'}
			</h3>
			<div className="review_block">
				<ul>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="no"
								name="question_16"
								className="required"
								value="No"
								onChange={setQ1}
								checked={questions.question_16 === 'No'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="no" />
							<label htmlFor="no" className="wrapper">
								No
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="maybe"
								name="question_16"
								className="required"
								value="Maybe"
								onChange={setQ1}
								checked={questions.question_16 === 'Maybe'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="maybe" />
							<label htmlFor="maybe" className="wrapper">
								Maybe
							</label>
						</div>
					</li>
					<li>
						<div className="checkbox_radio_container">
							<input
								type="radio"
								id="yes"
								name="question_16"
								className="required"
								value="Yes"
								onChange={setQ1}
								checked={questions.question_16 === 'Yes'}
								disabled={loader}
							/>
							<label className="radio" htmlFor="yes" />
							<label htmlFor="yes" className="wrapper">
								Yes
							</label>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
};
