import { useState, useEffect } from 'react';
import { QuestionApi } from '../Apis/apis';

export const Comments = ({ setQ1, loader, overLay, setLoader }) => {
	const [comments, setComments] = useState();

	const getComments = async () => {
		QuestionApi.getQuestionById(17)
			.then(({ question }) => {
				setComments(question.question);
				overLay.current.classList.remove('modal-open');
				setLoader(false);
			})
			.catch(error => {
				console.log(error);
			});
	};

	useEffect(() => {
		getComments();
		overLay.current.classList.add('modal-open');
		setLoader(true);
	}, []);

	return (
		// review_block
		<div className="form-group">
			<label htmlFor="additional_message_label">{comments ? comments : 'loading...'}</label>
			<textarea
				name="additional_message"
				id="additional_message_label"
				className="form-control"
				style={{ height: '120px' }}
				onKeyUp={setQ1}
				defaultValue={''}
				disabled={loader}
			/>
		</div>
	);
};
